import React from 'react';
import styled from 'styled-components';

const GraphTable = ({ values }) => {
    return (
        <StyledTable>
            <div className='table-row'>

                <div className='table-data flex-start'>
                    <p className='data-entry'>
                        Meses
                    </p>
                </div>


                <div className='table-data'>
                    <p className='data-entry'>
                        Total Investido
                    </p>
                </div>


                <div className='table-data'>
                    <p className='data-entry'>
                        Total em Juros
                    </p>
                </div>


                <div className='table-data'>
                    <p className='data-entry'>
                        Valor Total
                    </p>
                </div>
            </div>
            {
                values.months.map((month, key) =>
                    <div key={key} className='table-row'>

                        <div className='table-data flex-start'>
                            <p className='data-entry'>
                                {month}
                            </p>
                        </div>


                        <div className='table-data'>
                            <p className='data-entry'>
                                {(values.data2[key]).toLocaleString(undefined, { style: 'currency', currency: 'BRL' })}
                            </p>
                        </div>


                        <div className='table-data'>
                            <p className='data-entry'>
                                {(values.data1[key]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </p>
                        </div>


                        <div className='table-data'>
                            <p className='data-entry'>
                                {(values.data2[key] + values.data1[key]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </p>
                        </div>

                    </div>
                )
            }

        </StyledTable>
    );
}

export default GraphTable;

const StyledTable = styled.div`
    width: 100%;
    .data-entry{
        font-family: inherit;
        font-size: inherit;
    }
    .table-data{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #EBE9E8;
        padding: 0 0 0 4px;
        color: #35353B;
        line-height: 180%;
        font-family: inherit;
        font-size: inherit;
    }

    .flex-start{
        justify-content: flex-start;
        padding: 0 0 0 8px;
        box-sizing: border-box;
    }

    .table-row {
        width: 100%;
        height: 60px;
        padding: 0;
        display: flex;
        

        
        :nth-child(2n -1){
            background: #F7F6F5;
        }
        
        :nth-child(1){
            background: #EBE9E8;
            font-family: 'CASaygonSemiBold';
            & .table-data{
                border: 2px solid #CACACC;
                border-bottom: none;
            }
        }
        .flex-start{
            font-family: 'CASaygonSemiBold';
        }
    }


    @media(max-width: 1024px){
        font-size: 10px;
        .table-data{
            width: 25%;
        }
        .width50{
            width: 50%;
            max-width: 50%;
        }

        .table-row {
        width: 100%;
        height: 60px;
        padding: 0;
        display: flex;

            :nth-child(1){
                background: #EBE9E8;
                font-family: 'CASaygonSemiBold';
            }
        }
    }   

    @media print{
        width: 100%;
    .data-entry{
        font-family: inherit;
        font-size: inherit;
    }
    .table-data{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #EBE9E8;
        padding: 0 0 0 4px;
        color: #35353B;
        line-height: 180%;
        font-family: inherit;
        font-size: inherit;
    }

    .flex-start{
        justify-content: flex-start;
        padding: 0 0 0 8px;
        box-sizing: border-box;
    }

    .table-row {
        width: 100%;
        height: 20px;
        padding: 0;
        display: flex;
        

        
        :nth-child(2n -1){
            background: #F7F6F5;
        }
        
        :nth-child(1){
            background: #EBE9E8;
            font-family: 'CASaygonSemiBold';
            & .table-data{
                border: 2px solid #CACACC;
                border-bottom: none;
            }
        }
        .flex-start{
            font-family: 'CASaygonSemiBold';
        }
    }
    }

`;
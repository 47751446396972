import styled from 'styled-components';

const Button = styled.button`
    background: ${({ type }) => type === 'primary' ? '#00E169' : 'none'};
    border-radius: 4px;
    padding: 12px 30px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    width: 133px;
    height: 42px;
    font-size: 12px;

    :hover{
        cursor: pointer;
        background-color: ${({ type }) => type === 'primary' ? '#00E169DF' : 'none'};
    }

`
export {
    Button
};
import React from 'react';
import styled from 'styled-components';

const Menu = () => {
  return (
    <StyledMenu>
      <ul>
        <li class="dropdown">
          <a href='#' className="dropbtn">Cartões de crédito</a><span class="rotate-arrow"></span>
          <div class="dropdown-content">
            <a href="https://comparador.plusdin.com.br/cartao-de-credito/comparar">Comparador de Cartões</a>
            <a href="https://plusdin.com.br/ranking/cartao-de-credito">Ranking de Cartões</a>
            <a href="https://plusdin.com.br/institucional/cartao-de-credito/recomendacao">Recomendador de Cartões</a>
          </div>
        </li>
        <li>
          <a href="https://plusdin.com.br/emprestimo/">Empréstimos</a>
        </li>
        <li>
          <a href="https://plusdin.com.br/conta-digital/">Contas Digitais</a>
        </li>
        <li>
          <a href="https://plusdin.com.br/institucional/sobre-o-plusdin">Quem Somos</a>
        </li>
        <li>
          <a href="https://membros.plusdin.com.br/">Login</a>
        </li>
      </ul>
    </StyledMenu>
  );
};

export default Menu;

  const StyledMenu = styled.div`

    display: none;
    justify-items: flex-start;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      display: flex;
      align-items: center;

      li {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        padding: 0 16px;
        height: 32px;

        border-radius: 4px;

        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 0px;

        a {
          outline: none;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 120%;
          text-align: center;
          letter-spacing: 0.07em;
          text-transform: uppercase;

          color: #262b2b;

          &:hover {
            &::after {
              opacity: 1;
            }
          }
          &::after {
            content: '';
            margin-bottom: -0.25rem;
            opacity: 0;
            width: 100%;
            height: 2px;
            display: block;
            background-color: #00e169;
            transition: opacity 0.08s linear;
          }
        }
        &.dropdown {
          display: flex;
          position: relative;
          transition-delay: 3ms;
          transition-duration: 10ms;
  
          .rotate-arrow {
            /* margin-bottom: -.3rem; */
  
            &::before {
              content: '';
  
              position: relative;
              display: flex;
  
              width: 0.4em;
              height: 0.4em;
  
              margin-right: 0.5em;
  
              border-right: 0.2em solid #00e169df;
              border-top: 0.2em solid #00e169df;
              border-radius: 1px;
  
              transition: transform 0.3s;
              transform: rotate(45deg);
            }
          }
  
          &:hover {
            .rotate-arrow::before {
              transform: rotateZ(135deg);
              transition: transform 0.3s;
            }
  
            .dropdown-content {
              display: block;
            }
          }
  
          .dropdown-content {
            display: none;
            position: absolute;
  
            top: 105%;
            left: 18%;
            width: 265px;
  
            padding: 0;
  
            background-color: #fff;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
  
            z-index: 999;
  
            a {
              display: flex;
              flex-direction: row;
              position: relative;
  
              height: 17px;
              margin: 0 1.5rem;
              color: black;
              padding: 0;
              display: block;
              float: left;
  
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              text-transform: none;
  
              &:first-child,
              &:last-child {
                margin: 1.5rem;
              }
  
              &:hover {
                &::after {
                  content: '';
                  position: absolute;
                  display: block;
  
                  bottom: 0;
                  left: 0;
                  height: 2px;
                  margin-bottom: -0.25rem;
                  background-color: #00e169;
                }
              }
            }
          }
  
          .dropbtn {
            display: inline-block;
            padding: 0 16px;
            text-decoration: none;
          }
        }
      }
    }
  }
}

    @media(min-width: 1024px) {
      display: flex;
    }
  `;




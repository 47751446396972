import styled from 'styled-components';

const StyledMain = styled.div`
    display: flex;
    flex-direction: column;
    gap: 64px;

    @media(max-width: 1024px){
        gap: 32px;
    }
`;

export default StyledMain;
import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import PrintGraphContext from '../../Context/PrintGraphContext';

const VerticalBar = ({ input, height, width }) => {
    const {
        barRef
    } = useContext(PrintGraphContext);


    const data = {
        labels: input.months,
        datasets: [
            {
                stack: 0,
                label: 'Valor Investido',
                data: input.data2.map(item => item.toFixed(2)),
                backgroundColor: '#00E169',
                borderWidth: 1,
            },
            {
                stack: 0,
                data: input.data1.map(item => item.toFixed(2)),
                label: 'Total em Juros',
                backgroundColor: '#77A6F8',
                borderWidth: 1,
            },

        ],
    };
    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                display: false,
                ticks: {
                    major: {
                        enabled: true
                    },
                }
            },
            y: {
                display: false,
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
                align: 'start',
                labels: {
                    boxWidth: 16,
                    boxHeight: 16,
                    font: {
                        size: 12,
                        family: 'CASaygon',
                    }
                }
            }
        }
    }



    return (
        <div style={{ width: width, height: height, position: 'relative' }}>
            <Bar ref={barRef} data={data} options={options} />
        </div>
    );
}

export default VerticalBar;
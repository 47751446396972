import React from 'react';
import styled from 'styled-components';
import { IlustracaoHeader, ChevronDown } from '../Shared/Img';
import Header from '../Shared/Header/Header';

const TopBar = () => {
    return (
        <StyledTopBar id="top-bar">
            <div className={'navigation'}>
                <Header />
            </div>
            <div className='banner'>
                <div className='banner-text'>
                    <h1>Calculadora Conta Fácil</h1>
                    <p className='banner-subheader'>
                        Precisa fazer cálculos trabalhistas e outras simulações financeiras? A <strong>Calculadora Conta Fácil do Plusdin</strong> ajuda você a fazer contas sobre a sua vida financeira num piscar de olhos. Simule agora mesmo!

                    </p>
                </div>
                <StyledIlustracaoHeader />

            </div>
            <div className='seta'>
                <ChevronDown />
            </div>
        </StyledTopBar>
    )
}

export default TopBar;

const StyledTopBar = styled.div`
    background-color: #EBE9E8;
    padding: 28px 28px 32px 28px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    .navigation{
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
    }
    .banner {
        width: 100%;
        max-width: 1111px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    .ilustracao-header{
        width: 100%;
    }
    .banner-text{
        width: 446px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
    }

    .banner-subheader{
        font-size:18px;
        line-height: 150%;
    }

    .seta{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media(max-width:1024px){
        padding: 28px 24px;

        .banner{
            width: 100%;
            flex-direction: column-reverse;
            gap: 32px;
            align-items: center;
        }

        .banner-text{
            width: 100%;
        }

        .banner-subheader{
            font-size: 16px;
            line-height: 180%;
        }
    }
`;

const StyledIlustracaoHeader = styled(IlustracaoHeader)`
    width: 100%;
    height: 100%;
    max-width: 588.5px;
    max-height: 517.81px;
`
import React, { useContext } from 'react';
import { Pie } from 'react-chartjs-2';
import PrintGraphContext from '../../Context/PrintGraphContext';


const PieChart = ({ input, height, width }) => {

    const {
        pieRef
    } = useContext(PrintGraphContext);

    const data = {
        labels: ['Total Juros', 'Total Investido',],
        datasets: [
            {
                labels: ['Total Juros', 'Total Investido',],
                data: [input.data2[input.data2.length - 1].toFixed(2), input.data1[input.data1.length - 1].toFixed(2)],
                backgroundColor: [
                    '#00E169',
                    '#77A6F8',
                ],
                borderWidth: 0,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                align: 'start',
                labels: {
                    boxWidth: 16,
                    boxHeight: 16,
                    font: {
                        size: 12,
                        family: 'CASaygon',
                    }
                }
            }
        }
    }


    return (
        <div style={{ width: width, height: height, position: 'relative' }}>
            <Pie ref={pieRef} data={data} options={options} />
        </div>
    );
}

export default PieChart;
import React from 'react';
import styled from 'styled-components';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import Menu from '../Menu/Menu';
import { Logo } from '../Img';

const Header = () => {
  return (
    <StyledHeader>
      <a href="https://www.plusdin.com.br" className="logo-link">
        <Logo/>
      </a>

      <Menu />

      <HamburgerMenu />
    </StyledHeader>
  );
};

export default Header;

  const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-bottom: 0rem;
  margin: 0;
  background-color: #ebe9e8;
  a.logo-link {
    svg {
      width: 118.54px;
      height: 35.89px;
    }
  }

  @media(min-width: 460px) {
    a.logo-link {
      svg {
        width: 160px;
        height: 48px;
      }
    }
  }

  @media(min-width: 1024px) {
    .header-container {
      padding: 2rem;
    }
  }
`;
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NavigationCard = ({ calculator }) => {
    const { img, mainHeader, path, id } = calculator;

    const pushEventInteraction = (action) => {
        window.dataLayer.push({
            'event':'interaction',
            'interaction':{
                'category':'money-calculator',
                'action': action,
                'label':document.location.pathname,
                'value':0,
                'ni':false
            }
        });
    };

    const pushPageView = () => {
        window.dataLayer.push({
            'event':'virtual-pageview',
            'virtual-pageview':{
                'page': path
            }
        });
    };
    
    return (
        <StyledNavigationCard id={id} to={path} onClick={(event) => {
            pushEventInteraction(id);
            pushPageView();
        }}>
            <div className='img-header'>
                {img}
                <div className='subHeader'>
                    {mainHeader}
                </div>
            </div>
        </StyledNavigationCard>
    )
}



export default NavigationCard;

const StyledNavigationCard = styled(Link)`
    height: 188px;
    background-color: #FFFFFF;
    border: 2px solid #EBE9E8;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;

    .img-header{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

    }

    .subHeader{
        font-family: 'CASaygonSemiBold';
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 140px;
        color: #4A4B4C;
    }

    :hover{
        cursor: pointer;
    }

    @media(max-width: 1024px){
        
        height: 195px;
        padding: 16px 8px;

        .subHeader{
            width: 110px;
            height: 87px;
            font-size: 16px;
        }
    }
`;
import { unMaskReais } from './containers/Shared/Mask'
import {
    Salary13,
    CltPj,
    LiquidSalary,
    Saving,
    SimpleTax,
    Vacation,
    CompoundTax
} from './containers/Shared/Img';

const liquidSalary = (bruteSalary, descounts, descontDependents, calcInss, calcIrrf) => {
    if ([bruteSalary].some(value => value <= 0)) {
        return null;
    }

    const inss = {
        evento: 'INSS',
        ref: 'R$ 751,99',
        proventos: '-',
        descontos: 751.99,
    };

    const irrf = {
        evento: 'IRRF',
        ref: 0.275,
        proventos: '-',
        descontos: '-'
    }

    const total = {
        evento: 'Totais',
        ref: '',
        proventos: '-',
        descontos: '-'
    }

    const liquidSalary = {
        evento: 'Salário Líquido',
        ref: '',
        proventos: '-',
        descontos: ''
    }

    const arr = [
        {
            evento: 'Salário Bruto',
            ref: '-',
            proventos: bruteSalary,
            descontos: '-',
        },
        {
            evento: 'Descontos',
            ref: '-',
            proventos: '-',
            descontos: descounts === 0 ? '-' : descounts,
        },
    ];

    if (calcInss) {

        if (bruteSalary < 6433.57) {
            inss.ref = 0.14;
            inss.descontos = (bruteSalary - 3305.23) * inss.ref + 82.5 + 99.31 + 132.21;
        }

        if (bruteSalary < 3305.23) {
            inss.ref = 0.12;
            inss.descontos = (bruteSalary - 2203.49) * inss.ref + 82.5 + 99.31;
        }

        if (bruteSalary < 2203.49) {
            inss.ref = 0.09;
            inss.descontos = (bruteSalary - 1100.01) * inss.ref + 82.5;
        }

        if (bruteSalary < 1100.01) {
            inss.ref = 0.075;
            inss.descontos = bruteSalary * inss.ref;
        }
    } else {
        inss.evento = 'INSS';
        inss.ref = '-';
        inss.proventos = '-';
        inss.descontos = '-';

    }

    if (calcIrrf) {

        const baseSalaryIrrf = bruteSalary - inss.descontos - descontDependents;

        irrf.descontos = (baseSalaryIrrf - 4664.68) * irrf.ref + 205.56 + 138.65 + 69.2;

        if (baseSalaryIrrf < 4664.69) {
            irrf.ref = 0.225;
            irrf.descontos = (baseSalaryIrrf - 3751.06) * irrf.ref + 138.65 + 69.2;
        }

        if (baseSalaryIrrf < 3751.06) {
            irrf.ref = 0.15;
            irrf.descontos = (baseSalaryIrrf - 2826.66) * irrf.ref + 69.2;
        }

        if (baseSalaryIrrf < 2826.66) {
            irrf.ref = 0.075;
            irrf.descontos = (baseSalaryIrrf - 1903.99) * irrf.ref;
        }

        if (baseSalaryIrrf < 1903.99) {
            irrf.ref = 0;
            irrf.descontos = 0;
        }
    } else {

        irrf.evento = 'IRRF';
        irrf.ref = '-';
        irrf.proventos = '-';
        irrf.descontos = '-';

    }





    arr.push(inss);
    arr.push(irrf);
    total.descontos = arr.reduce((acc, cur) => typeof (cur.descontos) === 'number' ? acc + cur.descontos : acc + 0, 0);
    total.proventos = arr.reduce((acc, cur) => typeof (cur.proventos) === 'number' ? acc + cur.proventos : acc + 0, 0);
    liquidSalary.proventos = total.proventos - total.descontos;

    arr.push(total);
    arr.push(liquidSalary);
    return arr;
}

const calculatorsCards = [
    {
        path: '/savings',
        mainHeader: 'Poupança',
        mainHeaderDescription: 'Fique por dentro sobre quanto o seu dinheiro rende na poupança. É muito simples: basta inserir o valor inicial e seguir os passos que a nossa própria calculadora vai pedir. Teste aqui!',
        secHeader: 'e',
        img: <Saving />,
        id: 'poupanca',
        inputs: [
            {
                header: 'Valor Inicial',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Valor Mensal',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Taxa de Juros',
                config: ['Anual', 'Mensal'],
                configValue: 'Anual',
                label: '%',
                value: 4.375,
                disabled: true,
            },
            {
                header: 'Período em',
                config: ['Anos', 'Meses'],
                configValue: 'Anos',
                label: '',
                value: 0,
                disabled: false,
            },
        ],
        formula: function () {

            const initialValue = unMaskReais(this.inputs[0].value);
            const mensalAport = unMaskReais(this.inputs[1].value);
            const taxConfig = this.inputs[2].configValue;
            const taxRate = Number(this.inputs[2].value);
            const period = Number(this.inputs[3].value);
            const periodConfig = this.inputs[3].configValue;

            if ([initialValue, period].some(value => value <= 0)) {
                return null;
            }

            const taxRateValue = taxConfig === 'Anual' ? ((Math.pow((1 + (taxRate / 100)), (1 / 12))) - 1) : (taxRate / 100);
            const periodValue = periodConfig === 'Anos' ? period * 12 : period;
            const periodLabel = [];
            for (let i = 0; i <= periodValue; i++) { periodLabel.push(i) }

            const futureValue = periodLabel.map(item => (taxRate === 0) ? 0 :
                (
                    ((initialValue * (Math.pow(1 + taxRateValue, item)))) + ((mensalAport * (Math.pow(1 + taxRateValue, item) - 1)) / taxRateValue) - (initialValue + (mensalAport * item)))
            );

            const investedValue = periodLabel.map(item => initialValue + (mensalAport * item));

            return { months: periodLabel, data1: futureValue, data2: investedValue };

        },
        output: {
            type: 'historical',
            values: null,
        }
    },
    {
        path: '/simple-tax',
        mainHeader: 'Juros Simples',
        mainHeaderDescription: 'Tarefa moleza para nós. Você não precisa ser um matemático pós-graduado para fazer essa conta. Fazemos para você. Quer saber como? Clique aqui!',
        secHeader: 'e',
        img: <SimpleTax />,
        id: 'juros-simples',
        inputs: [
            {
                header: 'Valor Inicial',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Valor Mensal',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Taxa de Juros',
                config: ['Anual', 'Mensal'],
                configValue: 'Anual',
                label: '%',
                value: 0,
                disabled: false,
            },
            {
                header: 'Período em',
                config: ['Anos', 'Meses'],
                configValue: 'Anos',
                label: '',
                value: 0,
                disabled: false,
            },
        ],
        formula: function () {

            const initialValue = unMaskReais(this.inputs[0].value);
            const mensalAport = unMaskReais(this.inputs[1].value);
            const taxConfig = this.inputs[2].configValue;
            const taxRate = isNaN(Number(this.inputs[2].value)) ? 0 : Number(this.inputs[2].value);
            const period = Number(this.inputs[3].value);
            const periodConfig = this.inputs[3].configValue;

            if ([initialValue, period].some(value => value <= 0)) {
                return null;
            }

            const taxRateValue = taxConfig === 'Anual' ? (taxRate / (12 * 100)) : (taxRate / 100);
            const periodValue = periodConfig === 'Anos' ? period * 12 : period;
            const periodLabel = [];
            for (let i = 0; i <= periodValue; i++) { periodLabel.push(i) }

            const futureValue = periodLabel.map(item => (taxRate === 0) ? 0 : (initialValue + (mensalAport * item)) * taxRateValue * item)

            const investedValue = periodLabel.map(item => initialValue + (mensalAport * item));

            return { months: periodLabel, data1: futureValue, data2: investedValue };

        },
        output: {
            type: 'historical',
            values: null,
        }
    },
    {
        path: '/compount-tax',
        mainHeader: 'Juros Compostos',
        mainHeaderDescription: 'Se você deseja saber em detalhes como o seu dinheiro aumenta, este é o lugar certo. Aqui você terá a visão do aumento dos seus investimentos através do tempo. Saiba mais!',
        secHeader: 'e',
        img: <CompoundTax />,
        id: 'juros-compostos',
        inputs: [
            {
                header: 'Valor Inicial',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Valor Mensal',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Taxa de Juros',
                config: ['Anual', 'Mensal'],
                configValue: 'Anual',
                label: '%',
                value: 0,
                disabled: false,
            },
            {
                header: 'Período em',
                config: ['Anos', 'Meses'],
                configValue: 'Anos',
                label: '',
                value: 0,
                disabled: false,
            },
        ],
        formula: function () {

            const initialValue = unMaskReais(this.inputs[0].value);
            const mensalAport = unMaskReais(this.inputs[1].value);
            const taxConfig = this.inputs[2].configValue;
            const taxRate = Number(this.inputs[2].value);
            const period = Number(this.inputs[3].value);
            const periodConfig = this.inputs[3].configValue;

            if ([initialValue, period].some(value => value <= 0)) {
                return null;
            }

            const taxRateValue = taxConfig === 'Anual' ? ((Math.pow((1 + (taxRate / 100)), (1 / 12))) - 1) : (taxRate / 100);
            const periodValue = periodConfig === 'Anos' ? period * 12 : period;
            const periodLabel = [];
            for (let i = 0; i <= periodValue; i++) { periodLabel.push(i) }

            const futureValue = periodLabel.map(item => (!taxRate) ? 0 :
                (
                    ((initialValue * (Math.pow(1 + taxRateValue, item)))) + ((mensalAport * (Math.pow(1 + taxRateValue, item) - 1)) / taxRateValue) - (initialValue + (mensalAport * item)))
            );

            const investedValue = periodLabel.map(item => initialValue + (mensalAport * item));

            return { months: periodLabel, data1: futureValue, data2: investedValue };

        },
        output: {
            type: 'historical',
            values: null,
        }

    },
    {
        path: '/vacation',
        mainHeader: 'Férias',
        mainHeaderDescription: 'Ajudamos você a saber o quanto você vai receber no período das férias. A conta é super simples: basta somar o total do seu salário bruto e o valor de mais 1 / 3 dele. Confira aqui!',
        secHeader: 'e',
        img: <Vacation />,
        id: 'ferias',
        inputs: [
            {
                header: 'Salário Bruto',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Média de hora extra (Opcional)',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Número de dependentes',
                config: null,
                configValue: null,
                label: '',
                value: 0,
                disabled: false,
            },
            {
                header: 'Dias de Férias',
                config: null,
                configValue: null,
                label: '',
                value: 0,
                disabled: false,
            },
            {
                header: 'Abono pecuniário (Vende 1/3)',
                config: ['Não', 'Sim'],
                configValue: 'Não',
                label: null,
                value: 0,
                disabled: false,
            },
            {
                header: 'Adiantar 1ª parcela 13º:',
                config: ['Não', 'Sim'],
                configValue: 'Não',
                label: null,
                value: 0,
                disabled: false,
            },
        ],
        formula: function () {

            const bruteSalary = unMaskReais(this.inputs[0].value);
            const extraTime = unMaskReais(this.inputs[1].value);
            const numberDependents = Number(this.inputs[2].value) <= 0 ? 0 : Number(this.inputs[2].value) * 189.59;
            const vacationDays = Number(this.inputs[3].value);
            const sellVacation = this.inputs[4].configValue === 'Não' ? false : true;
            const salary13 = this.inputs[5].configValue === 'Não' ? false : true;

            if ([bruteSalary, vacationDays].some(item => item <= 0)) {
                return null;
            }

            const arr = [
                {
                    evento: this.inputs[0].header,
                    ref: '-',
                    proventos: (bruteSalary + extraTime) * vacationDays / 30,
                    descontos: '-',
                },
                {
                    evento: '1/3 de férias',
                    ref: '-',
                    proventos: bruteSalary / 3,
                    descontos: '-',
                },
                {
                    evento: 'Abono pecuniário',
                    ref: '-',
                    proventos: sellVacation ? bruteSalary / 3 : '-',
                    descontos: '-',
                },
                {
                    evento: '1/3 Abono pecuniário',
                    ref: '-',
                    proventos: sellVacation ? bruteSalary / 9 : '-',
                    descontos: '-',
                },
                {
                    evento: 'Adiantamento 1ª Parcela 13º',
                    ref: '-',
                    proventos: salary13 ? bruteSalary / 2 : '-',
                    descontos: '-',
                },
            ];

            const total = {
                evento: 'Totais',
                ref: '',
                proventos: '-',
                descontos: '-'
            };

            const liquidVacation = {
                evento: 'Férias Líquidas',
                ref: '',
                proventos: '-',
                descontos: ''
            };

            const totalBrutal = arr.reduce((acc, cur) => typeof (cur.proventos) === 'number' ? acc + cur.proventos : acc + 0, 0);
            const inssIrrf = liquidSalary(totalBrutal, 0, numberDependents, true, true).filter(item => item.evento === 'INSS' || item.evento === 'IRRF');
            inssIrrf.forEach(item => arr.push(item));
            total.descontos = arr.reduce((acc, cur) => typeof (cur.descontos) === 'number' ? acc + cur.descontos : acc + 0, 0);
            total.proventos = arr.reduce((acc, cur) => typeof (cur.proventos) === 'number' ? acc + cur.proventos : acc + 0, 0);
            liquidVacation.proventos = total.proventos - total.descontos;
            arr.push(total);
            arr.push(liquidVacation);

            return arr;

        },
        output: {
            type: 'table',
            values: null,
        }
    },
    {
        path: '/liquid-salary',
        mainHeader: 'Salário Líquido',
        mainHeaderDescription: 'Fazer a conta do salário líquido é simples.  E a nossa calculadora pode te ajudar nessa tarefa. O salário líquido nada mais é do que o valor entregue ao trabalhador depois que os descontos da carteira são debitados. Saiba mais!',
        secHeader: 'o',
        img: <LiquidSalary />,
        id: 'salario-liquido',
        inputs: [
            {
                header: 'Salário bruto',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Desconto',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Dependentes',
                config: null,
                configValue: null,
                label: '',
                value: 0,
                disabled: false,
            },
        ],
        formula: function () {

            const bruteSalary = unMaskReais(this.inputs[0].value);
            const descounts = unMaskReais(this.inputs[1].value);
            const descontDependents = Number(this.inputs[2].value) <= 0 ? 0 : Number(this.inputs[2].value) * 189.59;

            return liquidSalary(bruteSalary, descounts, descontDependents, true, true);

        },
        output: {
            type: 'table',
            values: null,
        }
    },
    {
        path: '/13',
        mainHeader: 'Décimo terceiro',
        mainHeaderDescription: 'Mais uma conta simples que nossa calculadora te entrega de bandeja. Para fazer o cálculo, é necessário saber o valor integral do seu salário. O resto é só deixar com a gente. Confira agora mesmo!', 
        secHeader: 'o',
        img: <Salary13 />,
        id: 'decimo-terceiro-salario',
        inputs: [
            {
                header: 'Salário Bruto',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Número de dependentes',
                config: null,
                configValue: null,
                label: '',
                value: 0,
                disabled: false,
            },
            {
                header: 'Número de meses trabalhados',
                config: null,
                configValue: null,
                label: '',
                value: 0,
                disabled: false,
            },
            {
                header: 'Parcela',
                config: ['Única', 'Primeira', 'Segunda'],
                configValue: 'Única',
                label: null,
                value: 0,
                disabled: false,
            },

        ],
        formula: function () {

            const bruteSalary = unMaskReais(this.inputs[0].value);
            const descontDependents = Number(this.inputs[1].value) <= 0 ? 0 : Number(this.inputs[1].value) * 189.59;
            const monthsWorked = Number(this.inputs[2].value) <= 0 ? 0 : Number(this.inputs[2].value)
            const parcela = this.inputs[3].configValue;

            if (bruteSalary === 0 || monthsWorked === 0) {
                return null;
            }
            const salary13 = bruteSalary * monthsWorked / 12;

            if (parcela === 'Única') {
                return liquidSalary(salary13, 0, descontDependents, true, true);
            }

            if (parcela === 'Primeira') {
                return liquidSalary(salary13, (salary13 / 2), descontDependents, false, false)
            }

            if (parcela === 'Segunda') {
                return liquidSalary(salary13, (salary13 / 2), descontDependents, true, true)
            }

            return null;

        },
        output: {
            type: 'table',
            values: null,
        }
    },
    {
        path: '/cltpj',
        mainHeader: 'Comparativo CLT e PJ',
        mainHeaderDescription: 'Entenda de que forma o seu salário vale mais - e lembre-se: a diferença entre CLT e PJ é que no regime CLT o trabalhador tem carteira assinada. Já no regime PJ, o trabalhador recebe um salário maior, mas arca com os benefícios por conta própria. Saiba mais', 
        secHeader: 'o',
        img: <CltPj />,
        id: 'comparativo-cltxpj',
        inputs: [
            {
                header: 'Salário Bruto',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Número de dependentes',
                config: null,
                configValue: null,
                label: '',
                value: 0,
                disabled: false,
            },
            {
                header: 'Custos Mensais com Transporte',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Custos Mensais com Alimentação',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Serviços Contábeis',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Taxa de Fiscalização (TFE) 1/12 avos',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },
            {
                header: 'Outros Custos',
                config: null,
                configValue: null,
                label: 'R$',
                value: 0,
                disabled: false,
            },

        ],
        formula: function () {

            const bruteSalary = unMaskReais(this.inputs[0].value);
            const transport = unMaskReais(this.inputs[2].value);
            const food = unMaskReais(this.inputs[3].value);
            const contabilServices = unMaskReais(this.inputs[4].value);
            const fiscalTax = unMaskReais(this.inputs[5].value);

            const total = {
                evento: 'Total a ser Cobrado',
                ref: '',
                proventos: 0,
                descontos: '-'
            }

            const irpj = {
                evento: 'Imposto de Pessoa Juridica',
                ref: '6%',
                proventos: 0,
                descontos: '-'
            }

            const arr = [
                {
                    evento: this.inputs[0].header,
                    ref: '',
                    proventos: bruteSalary,
                    descontos: '-'
                },
                {
                    evento: 'Férias + 1/3',
                    ref: '',
                    proventos: ((bruteSalary + bruteSalary / 3) / 12),
                    descontos: '-'
                },
                {
                    evento: 'Décimo 13º',
                    ref: '',
                    proventos: (bruteSalary / 12),
                    descontos: '-'
                },
                {
                    evento: this.inputs[2].header,
                    ref: '',
                    proventos: transport,
                    descontos: '-'
                },
                {
                    evento: this.inputs[3].header,
                    ref: '',
                    proventos: food,
                    descontos: '-'
                },
                {
                    evento: this.inputs[4].header,
                    ref: '',
                    proventos: contabilServices,
                    descontos: '-'
                },
                {
                    evento: this.inputs[5].header,
                    ref: '',
                    proventos: fiscalTax,
                    descontos: '-'
                },
                {
                    evento: 'FGTS',
                    ref: '',
                    proventos: (bruteSalary + (bruteSalary / 12) + ((bruteSalary + bruteSalary / 3) / 12)) * 0.08,
                    descontos: '-'
                },
                {
                    evento: this.inputs[6].header,
                    ref: '',
                    proventos: this.inputs[6].value,
                    descontos: '-'
                },
            ]

            total.proventos = arr.reduce((acc, cur) => typeof (cur.proventos) === 'number' ? acc + cur.proventos : acc + 0, 0);
            irpj.proventos = total.proventos / 0.94 - total.proventos;
            total.proventos = total.proventos / 0.94;

            arr.push(irpj);
            arr.push(total);

            return arr;

        },
        output: {
            type: 'table',
            values: null,
        }
    },
]

const reset = calculatorsCards.map(item => item);

export default calculatorsCards;

export { reset }
import React, { useContext } from 'react';
import styled from 'styled-components';
import { maskReais } from '../../Shared/Mask';
import NumberFormat from 'react-number-format';
import CalculatorContext from '../../../Context/CalculatorContext';

const Inputs = ({ name, input, calculatorId, numInputs }) => {
    const {
        header
    } = input
    const {
        handleChange
    } = useContext(CalculatorContext);

    return (
        <Input numInputs={numInputs}>
            <div className='input-header-div'>
                <p className='imput-header'>
                    {header}
                </p>
            </div>
            <div className='input-component'>
                {
                    input.type === 'date' ?
                        <>
                            <input
                                className='input'
                                key={name}
                                type='date'
                                placeholder={input.label === 'R$' ? 'R$ 0,00' : '0'}
                                onChange={(event) => handleChange(event.target.value, calculatorId, name, 'value')}
                                value={input.value === 0 ? '' : input.value}
                                disabled={input.disabled}
                            />
                        </> :
                        input.label === null ?
                            <select disabled={input.disabled} key={`config${name}`} className='select width100' name={name} onChange={(event) => handleChange(event.target.value, calculatorId, name, 'configValue')}>
                                {
                                    input.config.map((item, key) => <option key={key} value={item}>{item}</option>)
                                }
                            </select> :
                            input.label === '%' ?
                                <NumberFormat
                                    className={input.config === null ? 'input' : 'input merged-rigth'}
                                    key={name}
                                    type='text'
                                    placeholder={'0,00 %'}
                                    suffix={' %'}
                                    decimalSeparator={','}
                                    onValueChange={(event) => handleChange(event.floatValue, calculatorId, name, 'value')}
                                    value={input.value === 0 ? '' : input.value}
                                    disabled={input.disabled}
                                /> :
                                <input
                                    className={input.config === null ? 'input' : 'input merged-rigth'}
                                    key={name}
                                    type='text'
                                    placeholder={input.label === 'R$' ? 'R$ 0,00' : '0'}
                                    onChange={(event) => input.label === 'R$' ?
                                        handleChange(maskReais(event.target.value), calculatorId, name, 'value') :
                                        handleChange(event.target.value, calculatorId, name, 'value')}
                                    value={input.value === 0 ? '' : input.value}
                                    disabled={input.disabled}
                                />}
                {
                    input.config === null || input.label === null ? '' :
                        <select disabled={input.disabled} key={`config${name}`} className='select merged-left' onChange={(event) => handleChange(event.target.value, calculatorId, name, 'configValue')}>
                            {
                                input.config.map((item, key) => <option key={key} value={item}>{item}</option>)
                            }
                        </select>
                }

            </div>
        </Input>
    )
}

export default Inputs

const Input = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
    
    ${({ numInputs }) => (numInputs === 7) ?
        'flex: 1 0 33%;:nth-last-child(-n+3) {flex: 1 0 25%;}' :
        (numInputs === 6 || numInputs === 3) ?
            'flex: 1 0 25%;' :
            (numInputs === 5) ?
                'flex: 1 0 25%;:nth-last-child(-n+2) {flex: 1 0 33%;}' :
                'flex: 1 0 33%;'
    }

    .input-header-div{
        height: 100%;
        display: flex;
        align-items: flex-end;
    }
    .input-header{
        font-size: 14px;
        line-height: 170%;
        color: #000000;
    }
    .input-component{
        display: flex;
        width: 100%;
    }
    input{
        height: 35px;
        background-color: #FFFFFF;
        border: 1px solid #ACBBBB;
        box-sizing: border-box;
        border-radius: 4px; 
        padding: 8px;
        align-items: center;
        flex-grow: 1;
        font-family: 'CASaygon';
        width: 100%;
        font-size: 16px;
        :focus{
            outline: none;
        }
        :disabled{
            border: 1px solid #ACBBBB;
        }
    }
    .select{
        font-family: 'CASaygon';
        padding: 8px;
        height: 35px;
        border: 1px solid #ACBBBB;
        box-sizing: border-box;
        color: #262B2B;
        background-color: #FFFFFF;
        border-radius: 4px; 
        :disabled{
            border: 1px solid #ACBBBB;
        }
        :focus{
            outline: none;
        }
    }

    .merged-left{
        border-left: none;
        border-radius: 0 4px 4px 0;
    }

    .merged-rigth{
        border-radius: 4px 0 0 4px;
    }

    .width100{
        width: 100%;
    }
`;
import React, { useRef, useCallback } from 'react';
import PieChart from "./PieChart";
import VerticalBar from "./VerticalBar";
import styled from 'styled-components';
import Table from './Table';
import { Printer } from '../Shared/Img';
import { useReactToPrint } from 'react-to-print';
import PrintGraphContext from '../../Context/PrintGraphContext';
import GraphTable from './GraphTable';

const CalculatorCharts = ({ calculator }) => {

    const { values, type } = calculator.output;
    const printRef = useRef(null);
    const pieRef = useRef(null);
    const barRef = useRef(null);

    const reactToPrintContent = useCallback(() => {
        return printRef.current;
    }, []);

    const handleOnBeforeGetContent = useCallback(() => {
        if (type === 'historical') {
            barRef.current.resize(400, 200);
            pieRef.current.resize(200, 200);
        }
        return
    }, [barRef, pieRef, type,]);

    const handleAfterPrint = useCallback(() => {
        if (type === 'historical') {
            barRef.current.resize();
            pieRef.current.resize();
        }
        return
    }, [barRef, pieRef, type,]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        onBeforeGetContent: handleOnBeforeGetContent,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true,
    });

    if (values === null) {
        return <></>;
    }

    if (type === 'table') {
        return (
            <Output ref={printRef}>

                <div className='header'>
                    <h4>Resultado</h4>
                    <StyledPrinter onClick={handlePrint} />
                </div>

                <Table values={values} />

                <p className='discloser'>
                    *Os resultados dessa calculadora são simulações. Podendo assim, sofrer divergências causadas por mudanças nas regulamentações e taxas. Esta página tem caráter informativo, sem valor legal. Portanto, não dispensa a consulta de um profissional da área.
                </p>
            </Output>
        );
    }

    if (type === 'historical') {
        return (
            <Output ref={printRef}>

                <div className='header'>
                    <h4>Resultado</h4>
                    <StyledPrinter onClick={handlePrint} />

                </div>


                <div className='output-cards'>

                    <div className='output-card'>
                        <p className='output-title'>Valor Total Final</p>
                        <p className='result green'>{(values.data2[values.data2.length - 1] + values.data1[values.data1.length - 1]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                    </div>
                    <div className='output-card'>
                        <p className='output-title'>Valor Total Investido</p>
                        <p className='result'>{(values.data2[values.data2.length - 1]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                    </div>
                    <div className='output-card'>
                        <p className='output-title'>Total em Juros</p>
                        <p className='result blue'>{(values.data1[values.data1.length - 1]).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                    </div>
                </div>
                <div className='separator'></div>
                <PrintGraphContext.Provider value={{ pieRef, barRef }}>
                    <div className='output-graphs'>
                        <div className='pie-chart'>
                            <PieChart height={'100%'} width={'100%'} input={values} />
                        </div>
                        <div className='bar-chart'>
                            <VerticalBar height={'100%'} width={'100%'} input={values} />
                        </div>
                    </div>
                </PrintGraphContext.Provider>

                <GraphTable values={values} />
                <div>
                    <p className='discloser'>
                        *Os resultados dessa calculadora são simulações. Podendo assim, sofrer divergências causadas por mudanças nas regulamentações e taxas. Esta página tem caráter informativo, sem valor legal. Portanto, não dispensa a consulta de um profissional da área.
                    </p>
                </div>

            </Output>
        )
    }


    return (
        <>

        </>
    )
}

export default CalculatorCharts;

const Output = styled.div`

    display: flex;
    flex-direction: column;
    background: #FCFBFA;
    border: 2px solid #EBE9E8;
    box-sizing: border-box;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 32px;
    gap: 32px;
    width: 80vw;
    max-width: 1111px;
    margin: 0 auto;
    .header{
        display: flex;
        justify-content: space-between;
    }
    .output-cards{
        display: flex;
        gap: 16px;
    }


    .output-card{
        width: 100%;
        display: flex;
        gap: 8px;
        height: 127px;
        background: #FFFFFF;
        border: 2px solid #EBE9E8;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .output-title{
            color: #4A4B4C
        }

        .green{
            color: #00E169;
        }

        .blue{
            color: #77A6F8;
        }
    }

    .result{
            font-size: 24px;
            line-height: 130%;
            font-family: 'CASaygonBold';
            color: #4A4B4C;
        }

    .separator{
        background-color: #EBE9E8;
        width: 100%;
        height: 2px;
    }

    .output-graphs{
        display: flex;
        justify-content: space-between;
        height: 250px;
    }


    .bar-chart{
       width: calc(66% - 32px);
        height: 100%;
    }

    .pie-chart{
        width: calc(33% - 32px);
        height: 100%;
    }

    .discloser{
        color: #7F7F85;
        font-size: 12px;
        line-height: 150%;
        text-align: center;
    }

    @media(max-width:1024px){
        width: 100%;
        .output-cards{
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .result{
            font-size: 18px;

        }
        .output-card{
            width: 236px;
            height: 85px;
        }
        .output-graphs{
            flex-direction: column;
            width: 100%;
            height: 150vw;
        }

        .bar-chart{
            width: 100%;
            height: 50vw;
            position: relative;
        }

        .pie-chart{
            width: 100%;
            height: 100vw;
            position: relative;
        }
    }

    @media print{
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        border: none;
        box-sizing: border-box;
        box-shadow: none;
        border-radius: 8px;
        padding: 32px;
        gap: 32px;
        width: 100%;
        max-width: 1111px;
        margin: 0 auto;

        .header{
            display: flex;
            justify-content: space-between;
        }

        .output-cards{
            display: flex;
            gap: 16px;
            flex-direction: row;
        }

        .output-card{
            width: 100%;
            display: flex;
            gap: 8px;
            height: 127px;
            background: #FFFFFF;
            border: 2px solid #EBE9E8;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .output-title{
                color: #4A4B4C
            }

            .green{
                color: #00E169;
            }

            .blue{
                color: #77A6F8;
            }
        }

        .result{
                font-size: 24px;
                line-height: 130%;
                font-family: 'CASaygonBold';
                color: #4A4B4C;
            }

        .separator{
            background-color: #EBE9E8;
            width: 100%;
            height: 2px;
        }

        .output-graphs{
            display: flex;
            position: relative;
            width: 100%;
            height: 200px;
            flex-direction: row;
        }


        .bar-chart{
            width: calc(66% - 32px - 4cm);
            height: 100%;
            position: absolute;
            top: 0;
            right: 4.1cm;
        }

        .pie-chart{
            width: calc(33% - 32px - 4cm);
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }


        .discloser{
            color: #7F7F85;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
        }  
    }
    
`

const StyledPrinter = styled(Printer)`
    cursor: pointer;
`
import { createGlobalStyle } from 'styled-components';
import CASaygonTtf from '../assets/fonts/CASaygonText/CASaygonText-Regular.ttf'
import CASaygonBoldTtf from '../assets/fonts/CASaygonText/CASaygonText-Bold.ttf'
import CASaygonSemiBoldTtf from '../assets/fonts/CASaygonText/CASaygonText-SemiBold.ttf'

const GlobalStyle = createGlobalStyle`

@font-face {
	font-family: CA SaygonText,sans-serif;
	src: url(${CASaygonTtf}) format('truetype');
}

@font-face {
	font-family: CASaygonBold,sans-serif;
	src: url(${CASaygonBoldTtf}) format('truetype');
}

@font-face {
	font-family: CASaygonSemiBold,sans-serif;
	src: url(${CASaygonSemiBoldTtf}) format('truetype');
}

@page {
    size: portrait;
    margin: 1cm;
}


html,button, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
  font-family: CASaygon,sans-serif;

}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

*{
    box-sizing: border-box;
}
a {
	text-decoration: none;
	color: inherit;
	font-family: inherit;
}
body {
	background-color: #F7F6F5;
}
button{
	cursor: pointer;
}

h1{
	font-size: 48px;
	line-height: 57.6px;
	font-family: CASaygonBold,sans-serif;
	color: #151517;
}

p{
	font-size: 14px;
	line-height: 23.8px;
}

h2{
	font-size: 36px;
	font-family: CASaygonSemiBold,sans-serif;
	color: #151517;
	line-height: 130%;
}

h3{
	font-size: 30px;
	font-family: CASaygonSemiBold,sans-serif;
	color: #151517;
	line-height: 130%;
}

h4{
	font-size: 20px;
	font-family: CASaygonSemiBold,sans-serif;
	color: #151517;
	line-height: 130%;
}
  
  @include media('>desktop') {
	.hamburger-menu-container {
	  display: none;
	}
  }
  
  @include media('>desktop') {
	.hamburger-menu-container {
	  display: none;
	}
  }  

@media(max-width: 1024px){
	h1{
	font-size: 30px;
	line-height: 130%;
	font-family: CASaygonSemiBold,sans-serif;
	color: #151517;
	}

	p{
		font-size: 12px;
		line-height: 23.8px;
	}

	h2{
		font-size: 30px;
		line-height: 130%;
	}

	h3{
		font-size: 24px;
	}

	h4{
		font-size: 16px;
		line-height: 180%;
	}
}
`

export default GlobalStyle;


import { ReactComponent as Logo } from '../../assets/img/logo_plusdin_principal.svg';
import { ReactComponent as GreenLogo } from '../../assets/img/logoPlusdinGreen.svg';
import { ReactComponent as IlustracaoHeader } from '../../assets/img/IlustraçãoHeader.svg';
import { ReactComponent as Salary13 } from '../../assets/img/Salary13.svg';
import { ReactComponent as CltPj } from '../../assets/img/CltPj.svg';
import { ReactComponent as LiquidSalary } from '../../assets/img/LiquidSalary.svg';
import { ReactComponent as Recision } from '../../assets/img/Recision.svg';
import { ReactComponent as Saving } from '../../assets/img/Saving.svg';
import { ReactComponent as SimpleTax } from '../../assets/img/SimpleTax.svg';
import { ReactComponent as Vacation } from '../../assets/img/Vacation.svg';
import { ReactComponent as CompoundTax } from '../../assets/img/CompoundTax.svg';
import { ReactComponent as ChevronDown } from '../../assets/img/chevron-down.svg';
import { ReactComponent as ChevronRight } from '../../assets/img/chevron-right.svg';
import { ReactComponent as LeftArrow } from '../../assets/img/arrow-select.svg';
import { ReactComponent as Info } from '../../assets/img/Info.svg';
import { ReactComponent as Printer } from '../../assets/img/Printer.svg';
import { ReactComponent as HamburgerMenuIcon } from '../../assets/img/hamburgerMenuIcon.svg'
import { ReactComponent as LinkTop } from '../../assets/img/linkTop.svg'
import { ReactComponent as FacebookIcon } from '../../assets/img/social/face.svg'
import { ReactComponent as InstagramIcon } from '../../assets/img/social/insta.svg'
import { ReactComponent as LinkedinIcon } from '../../assets/img/social/linkedin.svg'

export {
    Logo,
    GreenLogo,
    IlustracaoHeader,
    Salary13,
    CltPj,
    LiquidSalary,
    Recision,
    Saving,
    SimpleTax,
    Vacation,
    CompoundTax,
    ChevronDown,
    ChevronRight,
    LeftArrow,
    Info,
    Printer,
    HamburgerMenuIcon,
    LinkTop,
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon 
};
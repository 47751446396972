import styled from 'styled-components';
import { slide as Menu } from 'react-burger-menu';
import { HamburgerMenuIcon, Logo } from '../Img';

const HamburgerMenu = () => {
  return (
    <StyledHamburgerMenu>
      <Menu width="320px" right noOverlay customBurgerIcon={ <HamburgerMenuIcon/> }>
        <a href="https://www.plusdin.com.br" className="hamburger-menu-logo">
          <Logo/>
        </a>

        <ul>
          <li>
            <div id="myaccordion">
            <input type="checkbox" id="accordion" className='hidecontent' />
            <label htmlFor="accordion">
        
              <span>Cartões de crédito</span>
              <span class="rotate-arrow"></span>
          
            </label>
              <ul className='content hidecontent'>
                <li className="menu-item">
                  <a href="https://comparador.plusdin.com.br/cartao-de-credito/comparar">Comparador de cartões</a>
                </li>
                <li className="menu-item">
                  <a href="https://plusdin.com.br/ranking/cartao-de-credito">Ranking de cartões</a>
                </li>
                <li className="menu-item">
                  <a href="https://plusdin.com.br/institucional/cartao-de-credito/recomendacao">Recomendador de cartões</a>
                </li>
              </ul>
            </div>
          </li>
          <li className="menu-item hamburguer">
            <a href="https://plusdin.com.br/emprestimo/">Empréstimos</a>
          </li>
          <li className="menu-item hamburguer">
            <a href="https://plusdin.com.br/conta-digital/">Contas Digitais</a>
          </li>
          <li className="menu-item hamburguer">
            <a href="https://plusdin.com.br/institucional/sobre-o-plusdin">Quem Somos</a>
          </li>
          <li className="menu-item hamburguer">
            <a href="https://membros.plusdin.com.br/">Login</a>
          </li>
        </ul>
      </Menu>
    </StyledHamburgerMenu>
  );
};

export default HamburgerMenu;

const StyledHamburgerMenu = styled.div`
  .hamburger-menu-logo {
    position: flex;
    top: -15px;
  }

  .bm-burger-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    background-color: #00e169;
    border-radius: 50%;
    position: relative;

    #react-burger-menu-btn {
      width: 46px !important;
      height: 46px !important;
    }

    svg {
      width: 20px !important;
      height: 14px !important;
    }
  }

  .bm-burger-bars {
    background: #373a47;
  }

  .bm-burger-bars-hover {
    background: #a90000;
  }

  .bm-cross-button {
    height: 24px;
    width: 24px;
    color: rgba(0, 0, 0, 1) !important;
  }

  .bm-cross {
    background: #000;
    height: 18px !important;
  }

  .bm-menu-wrap {
    position: fixed;
    top: 0;

    height: 100%;
  }

  .bm-menu {
    background: #00e169;
    padding: 2.5em 1.5em 0;
  }

  .bm-morph-shape {
    fill: #373a47;
  }

  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  ul.bm-item {
    display: inline-block;
    margin: 0;
    padding: 0;

    margin-top: 2.5rem;

    li {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 0.8px solid rgba(0, 0, 0, 0.6);

      a {
        font-size: 20px;
        line-height: 130%;
        --text-opacity: 1;
        color: #000;
        color: rgba(0, 0, 0, var(--text-opacity));
        font-weight: 600;
      }

      &:last-child {
        border-bottom: 0;
      }

      #myaccordion {
        margin: 0;
        padding: 0;
  
        label {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
  
          span {
            color: #000;
            font-weight: 600;
            font-size: 20px;
            line-height: 130%;
  
            opacity: 1;
          }
  
          .rotate-arrow {
            width: 0.5em;
            height: 0.5em;
  
            &::before {
              content: '';
              display: flex;
  
              width: 0.5em;
              height: 0.5em;
  
              margin-right: 0.5em;
  
              border-right: 0.2em solid #000;
              border-top: 0.2em solid #000;
              border-radius: 1px;
  
              transition: transform 0.3s;
              transform: rotate(45deg);
            }
          }
        }
        ul.content {
          background: #00e169;
          padding: 1.5rem 0 1.5rem 1.5rem;
  
          li {
            &:last-child {
              margin-bottom: 0;
              padding-bottom: 0;
            }
  
            a {
              font-size: 18px;
              line-height: 130%;
              opacity: 1;
              color: #000;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

.menu-item {
  display: block;
  width: 100%;

  padding-bottom: 1rem;
  margin-bottom: -1rem;
  
  border-bottom: 0.8px solid rgba(0, 0, 0, 0.6);
  
  transition: ease 0.25s;
}
  
.hidecontent {
  display: none;
}
  
#myaccordion input:checked + label {
  span.rotate-arrow::before {
    transform: rotateZ(135deg);
    transition: transform 0.3s;
  }
}
  
#myaccordion input:checked + label + .content {
  display: block;
  webkit-animation: fadeIn 0.5s ease-out;
  -moz-animation: fadeIn 0.5s ease-out;
  -o-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}
  
@-webkit-keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

  #react-burger-cross-btn {
    color: #000;
  }

  @media(min-width: 1024px) {
    display: none;
  }
`;

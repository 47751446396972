import React, { useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Inputs from './Inputs';
import styled from 'styled-components';
import { Button } from '../../../Style/styledComponents';
import CalculatorContext from '../../../Context/CalculatorContext';
import { LeftArrow, Info } from '../../Shared/Img';


const Calculator = ({ calculator, calculatorId }) => {
    const pushEventInteraction = (type, action) => {
        window.dataLayer.push({
            'event':'interaction',
            'interaction':{
                'category':'money-calculator',
                'action': `${type}:${action}`,
                'label':document.location.pathname,
                'value':0,
                'ni':false
            }
        });
    };
    const {
        mainHeader,
        mainHeaderDescription,
        inputs,
        secHeader,
        id
    } = calculator

    const {
        handleCalculation,
        cleanCalculator
    } = useContext(CalculatorContext);

    const ref = useRef(null);
    const executeScroll = () => ref.current.scrollIntoView();

    useEffect(executeScroll, []);

    return (
        <StyledCalculator ref={ref}>
            <div className='calculator-main'>
                <div className='calculator-main-header'>
                    <Link to='/' onClick={(event) => {
                        pushEventInteraction('voltar', id);
                    }}>
                        <LeftArrow /> <span className='back-button'>VOLTAR</span>
                    </Link>
                    <h2>{mainHeader}</h2>
                    <p className='calculator-sub-header'>
                        {mainHeaderDescription}
                    </p>
                </div>
                <div className='calculator-area'>
                    <h4 className='calculator-area-header'>Simulador d{secHeader} {mainHeader} <StyledInfo width={'18px'} height={'18px'} /></h4>
                    <div className='input-area'>
                        {
                            inputs.map((input, key) =>
                                <Inputs key={key}
                                    name={key}
                                    input={input}
                                    calculatorId={calculatorId}
                                    numInputs={inputs.length}
                                />)
                        }
                    </div>
                    <div className='buttons-separator'></div>
                    <div className='buttons'>
                        <Button type={'primary'} onClick={() => {
                            handleCalculation(calculatorId);
                            pushEventInteraction('calcular', id);
                        }} >CALCULAR</Button>
                        <Button onClick={() => {
                            cleanCalculator(calculator);
                            pushEventInteraction('limpar', id);
                        }} >LIMPAR</Button>
                    </div>
                </div>
            </div>


            <div className='calculator-info'>

            </div>
        </StyledCalculator >
    );
}

export default Calculator;

const StyledCalculator = styled.div`
   width: 80vw;
   max-width: 1111px;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
    gap: 32px;
    
    .back-button{
        font-family: 'CASaygonSemiBold';
        color:#7F7F85;
        font-size: 12px;
        line-height: 150%;
    }
   .calculator-main{
        display: flex;
        justify-content: space-between;
   }

   .calculator-main-header{
        width: 100%;
        max-width: 339px;
        display: flex;
        flex-direction: column;
        gap: 16px;
   }

   .calculator-sub-header{
        font-family: 'CASaygon';
        font-size: 16px;
        line-height: 180%;
   }

   .calculator-area{
        background: #FCFBFA;
        border: 2px solid #EBE9E8;
        box-sizing: border-box;
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        width: 100%;
        max-width: 740px;
        min-height: 263px;
        padding: 32px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 32px;
   }

   .calculator-area-header{
       display: flex;
       align-items: center;
       gap: 11px;
    }
   .input-area{
       display: flex;
       flex-wrap: wrap;
       gap: 32px;
   }
   .buttons{
       display: flex;
       width: 100%;
       justify-content: flex-end;
   }
   .buttons-separator{
       display: none;
   }

   @media(max-width: 1024px){
       width: 100%;
       padding: 0 30px;

       .calculator-main{
           flex-direction: column;
           gap: 32px;
       }
       .calculator-main-header{
           max-width: 100%;
       }
       .calculator-sub-header{
            font-size: 14px;
            line-height: 170%;
       }

       .calculator-area{
            padding: 16px;
            gap: 16px;
            margin: 0 auto;
       }

       .input-area{
           flex-direction: column;
           gap: 16px;
       }
       .buttons-separator{
           display: initial;
           width: 100%;
           height: 2px;
           background-color: #EBE9E8;
       }
       .buttons{
           justify-content: space-around;
       }
   }
`;

const StyledInfo = styled(Info)`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    display: initial;
    vertical-align: middle;
`

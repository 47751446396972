import React from 'react';
import styled from 'styled-components';
import { Logo, GreenLogo, LinkTop, FacebookIcon, InstagramIcon, LinkedinIcon, ChevronRight } from '../Img';

const Footer = () => {

  const scrollToSection = (event, section) => {
    event.preventDefault();

    if (section) {
      const sectionBlock = document.getElementById(section);

      sectionBlock.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
      <StyledFooter>
        <div className="footer-wrapper">
          <div className="container">
            <div className="menu">
              <a href="https://www.plusdin.com.br" className="footer-menu-logo">
                <GreenLogo />
              </a>

              <div className="footer-panel_card" data-testid="footer-main-menu">
                <input type="checkbox" id="footer-category-1" name="ft" />
                
                <label className="footer-panel_card-label" htmlFor="footer-category-1">
                  Cartões de Crédito
                  <span>
                    <ChevronRight />
                  </span>
                </label>
                <div className="footer-panel_card-content">
                  <ul>
                    <li>
                      <a target="_blank" href="https://plusdin.com.br/cartao-de-credito/os-melhores/" rel="noreferrer">
                        Os Melhores
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://plusdin.com.br/institucional/cartao-de-credito/recomendacao" rel="noreferrer">
                        Recomendação
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://comparador.plusdin.com.br/cartao-de-credito/comparar" rel="noreferrer">
                        Compare os cartões
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <ul className="footer-desktop-list">
                <li className="footer-outer-list">
                  Cartões de Crédito
                </li>
                <li>
                  <a target="_blank" href="https://plusdin.com.br/cartao-de-credito/os-melhores/" rel="noreferrer">
                    Os Melhores
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://plusdin.com.br/institucional/cartao-de-credito/recomendacao" rel="noreferrer">
                    Recomendação
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://comparador.plusdin.com.br/cartao-de-credito/comparar" rel="noreferrer">
                    Compare os cartões
                  </a>
                </li>
              </ul>

              <div className="footer-panel_card">
                <input type="checkbox" id="footer-category-2" name="ft" />
                <label className="footer-panel_card-label" htmlFor="footer-category-2">
                  Contas Digitais
                  <span>
                    <ChevronRight />
                  </span>
                </label>
                <div className="footer-panel_card-content">
                  <ul>
                    {/* <li>
                      <a target="_blank" href="https://plusdin.com.br/conta-digital/" rel="noreferrer">
                        As Melhores
                      </a>
                    </li> */}
                    {/* <li>
                      <a target="_blank" href="https://quiz.plusdin.com.br/cd2/std/def/index.html" rel="noreferrer">
                        Recomendação
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>

              <ul className="footer-desktop-list">
                <li className="footer-outer-list">
                    Contas Digitais
                </li>
                {/* <li>
                  <a target="_blank" href="https://plusdin.com.br/conta-digital/" rel="noreferrer">
                    As Melhores
                  </a>
                </li> */}
                {/* <li>
                  <a target="_blank" href="https://quiz.plusdin.com.br/cd2/std/def/index.html" rel="noreferrer">
                    Recomendação
                  </a>
                </li> */}
              </ul>

              <div className="footer-panel_card">
                <input type="checkbox" id="footer-category-3" name="ft" />
                <label className="footer-panel_card-label" htmlFor="footer-category-3">
                  Empréstimos
                  <span>
                    <ChevronRight />
                  </span>
                </label>
                <div className="footer-panel_card-content">
                  <ul>
                    {/* <li>
                      <a target="_blank" href="https://plusdin.com.br/emprestimo/" rel="noreferrer">
                        Os Melhores
                      </a>
                    </li> */}
                    <li>
                      <a target="_blank" href="https://responda.plusdin.com.br/plusdin-quiz-emprestimo-2/" rel="noreferrer">
                        Recomendação
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <ul className="footer-desktop-list">
                <li className="footer-outer-list">
                  Empréstimos
                </li>
                {/* <li>
                  <a target="_blank" href="https://plusdin.com.br/emprestimo/" rel="noreferrer">
                    Os Melhores
                  </a>
                </li> */}
                <li>
                  <a target="_blank" href="https://responda.plusdin.com.br/plusdin-quiz-emprestimo-2/" rel="noreferrer">
                    Recomendação
                  </a>
                </li>
              </ul>

              <div className="footer-panel_card">
                <input type="checkbox" id="footer-category-4" name="ft" />
                <label className="footer-panel_card-label" htmlFor="footer-category-4">
                  Plusdin News
                  <span>
                    <ChevronRight />
                  </span>
                </label>
                <div className="footer-panel_card-content">
                  <ul>
                    <li>
                      <a target="_blank" href="https://plusdin.com.br/cartao-de-credito/" rel="noreferrer">
                        Cartões de Crédito
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://plusdin.com.br/conta-digital/" rel="noreferrer">
                        Contas Digitais
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://plusdin.com.br/emprestimo/" rel="noreferrer">
                        Empréstimos
                      </a>
                    </li>
                    {/* <li>
                      <a target="_blank" href="https://plusdin.com.br/cartao-de-credito/recomendacao/" rel="noreferrer">
                        Notícias
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
        
              <ul className="footer-desktop-list">
                <li className="footer-outer-list">
                    Plusdin News
                </li>
                <li>
                  <a target="_blank" href="https://plusdin.com.br/cartao-de-credito/" rel="noreferrer">
                    Cartões de Crédito
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://plusdin.com.br/conta-digital/" rel="noreferrer">
                    Contas Digitais
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://plusdin.com.br/emprestimo/" rel="noreferrer">
                    Empréstimos
                  </a>
                </li>
                {/* <li>
                  <a target="_blank" href="https://plusdin.com.br/cartao-de-credito/recomendacao/" rel="noreferrer">
                    Notícias
                  </a>
                </li> */}
              </ul>

              <ul className="footer-last-column">
                <li>
                  <a href="https://calculadoras.plusdin.com.br/" target="_self">
                    Calculadoras
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://plusdin.com.br/institucional/sobre-o-plusdin" rel="noreferrer">
                    Sobre o Plusdin
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://plusdin.com.br/institucional/fale-conosco" rel="noreferrer">
                    Fale Conosco
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://plusdin.com.br/institucional/trabalhe-conosco" rel="noreferrer">
                    Trabalhe Conosco
                  </a>
                </li>
                <li>
                  <div className="social-icons">
                    <a href="https://www.instagram.com/plusdin/?utm_source=plusdin.com.br&utm_medium=link" data-testid="footer-instagram-link">
                      <InstagramIcon/>
                    </a>
        
                    <a href="https://www.facebook.com/Plusdin-101107134902695?utm_source=plusdin.com.br&utm_medium=link" data-testid="footer-facebook-link">
                      <FacebookIcon />
                    </a>
        
                    <a href="https://linkedin.com/company/plusdin?utm_source=plusdin.com.br&utm_medium=link" data-testid="footer-linkedin-link">
                      <LinkedinIcon/>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <hr />

          <div className="container">
            <div className="copyright" data-testid="footer-copyright">
              <div className="footer-company">
                <span>PLUSDIN TECNOLOGIA E INFORMAÇÃO LTDA.</span>
                <span>CNPJ: 35.265.373/0001-79</span>
                <span>by ETUS Media Holding Ltda - 27.670.034/0001-40</span>
              </div>
        
              <div className="footer-institucional">
                <div className="footer-institucional-terms">
                  <a href="https://plusdin.com.br/institucional/termos-de-uso" target="_blank" rel="noopener noreferrer">Termos de Uso |</a>
                  <a href="https://plusdin.com.br/institucional/politica-de-privacidade" target="_blank" rel="noopener noreferrer"> Política de Privacidade |</a>
                  <a href="https://plusdin.com.br/institucional/disclaimer" target="_blank" rel="noopener noreferrer"> Disclaimer</a>
                </div>
                <span>&copy; 2021 - Plusdin - Todos os direitos reservados</span>
              </div>
            </div>
            
            <a href="#main" onClick={(event) => {
              event.preventDefault();
              scrollToSection(event, 'top-bar')
            }} data-testid="footer-scroll-top-button" className="scroll-top-button">
              <LinkTop />
            </a>

          </div>
        </div>
      </StyledFooter>
    );
  };

export default Footer;

const StyledFooter = styled.footer`
  background-color: #4a4b4c;

  hr {
    margin: 0;
    padding: 0;
    opacity: 0.3;
    border-top: 1px solid white;
  }

  .footer-wrapper{
    padding: 0 2rem;
    max-width: 1100px;
    margin: 0 auto;
  }

  .container {
    max-width: 1110px;
    margin: 0 auto;
  }

  .menu {
    padding: 3.75rem 1.375rem 1.625rem 0rem;

    .footer-desktop-list {
      display: none;
    }

    ul {
      margin: 1rem 0 0 0;
      padding: 0;
      list-style: none;
      color: $white;

      li {

        padding-top: 1.5rem;
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 15px;

        ul {
          margin: 0.5rem 0 0 1.688rem;

          li {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 170%;
          }
        }
      }

      li.footer-outer-list {
        margin-bottom: 1.75rem;
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 170%;
        text-decoration: underline;
      }
    }

    .footer-last-column {
      li {
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 150%;
        text-decoration: none;
        color: #fff;
      }

      .social-icons {
        display: flex;
        flex-direction: row;
        margin-top: 0rem;

        a {
          outline: none;
          margin-right: 0.813rem;

          svg {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }

    .footer-panel_card {
      margin-top: 1.5rem;
      width: 100%;
      overflow: hidden;
      max-width: 20rem;

      .footer-panel_card-label {
        color: white;
        display: flex;
        display: -webkit-flex;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 170%;
        text-decoration: underline;

        span {
          padding-left: 0.875rem !important;
        }

        svg {
          display: inline-block;
          transition: all 0.25s ease;
        }
      }

      .footer-panel_card-content {
        max-height: 0;
        padding: 0 1rem;
        color: white;
        transition: all 0.25s ease;
        font-size: 0.75rem;
        padding-bottom: 0.2rem;

        ul {
          margin: 0;
        }

        p {
          margin-top: 0rem;
          margin-bottom: 0.5rem;
          line-height: 180%;
        }

        a {
          color: white;
        }
      }

      input {
        display: none;
      }

      input:checked + .footer-panel_card-label {
        border-radius: 0;

        svg {
          transform: rotateX(180deg);
        }
      }

      input:checked ~ .footer-panel_card-content {
        max-height: 234vh;
      }
    }
  }


  .copyright {
    padding: 1.375rem 0rem 1.625rem 0rem;
    color: #FFF;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 0.75rem;

    .footer-company {
      span {
        display: block;
        font-size: 0.818rem;
      }
    }

    .footer-institucional {
      a {
        text-decoration: underline;
      }
      .footer-institucional-terms {
        line-height: 1rem;
      }
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 150%;
    }
  }

  .scroll-top-button {
    position: fixed;
    bottom: 1.1rem;
    right: 1.438rem;

    outline: none;

    img {
      width: 44px;
      height: 45px;
    }
  }
  
  @media(min-width: 1024px) {
    .footer-wrapper {
      padding: 0 1rem;
    }

    .container {
      color: #FFF;
      .menu {
        display: flex;
        flex-direction: row;

        .footer-menu-logo {
          display: block;
          flex: 100%;
          height: fit-content;
        }

        ul {
          flex: 1 100%;
          margin: 0;

          li {
            font-size: 0.875rem;
          }

          li.footer-outer-list {
            font-size: 1rem;
            margin-bottom: 0rem;
            padding-top: 0rem;
          }
        }

        .footer-panel_card {
          display: none;
        }

        .footer-desktop-list {
          display: block;
          padding-right: 0.5rem;
        }

        .footer-last-column {
          li {
            padding-top: 0rem;
            padding-bottom: 1.5rem;
          }
          a {
            font-size: 1rem;
          }

          .social-icons {
            margin-top: 0rem;
          }
        }
      }

      .copyright {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .footer-company {
          display: flex;
          flex-direction: column;
          text-align: left;
        }

        .footer-institucional {
          display: flex;
          flex-direction: column;

          .footer-institucional-terms {
            display: inline-block;
          }

          a {
            font-size: 0.75rem;
            line-height: 150%;
            font-weight: 400;
            margin: 0;
            display: inline-block;
            padding-right: 0.2rem;
          }
        }

        span {
          flex: 1 50%;
        }

        .social-icons {
          flex: 1 22%;
          margin-top: 0;
          align-items: flex-end;
        }
      }
    }
  }
`;

import React, { useState } from 'react';
import {
	BrowserRouter,
	Switch,
	Route,
} from "react-router-dom";
import GlobalStyle from './Style/GlobalStyled';
import Calculator from './containers/pages/Calculator/Calculator';
import calculatorsCards, { reset } from './calculatorCards';
import TopBar from './containers/TopBar/TopBar';
import Footer from './containers/Shared/Footer/Footer';
import StyledMain from './StyledMain';
import Navigation from './containers/pages/navigation/Navigation';
import CalculatorContext from './Context/CalculatorContext';
import CalculatorCharts from './containers/chart/CalculatorCharts';
import { SavingsInfo } from './containers/Shared/CalculatorsInfo';
import ScrollTop from './containers/Shared/ScrollTop';

function App() {

	const [calculators, setCalculators] = useState(calculatorsCards);

	const handleChange = (value, calculator, input, configValue) => {
		const newCalculators = [...calculators];
		newCalculators[calculator].inputs[input][configValue] = value;
		newCalculators[calculator].inputs[input][configValue] = value;
		newCalculators[calculator].output.values = newCalculators[calculator].formula();
		setCalculators(newCalculators);

	}

	const handleCalculation = (calculator) => {
		const newCalculators = [...calculators];
		newCalculators[calculator].output.values = newCalculators[calculator].formula();
		setCalculators(newCalculators);
	}

	const cleanCalculator = (calculator) => {
		const newCalculators = [...calculators];
		newCalculators[calculator] = { ...reset[calculator] };
		setCalculators(newCalculators);
	}

	const checkRobotsTxt = () => {
		if(window.location.pathname.indexOf('robots.txt') !== -1) {
			window.location.href = '/robots.txt'
		}
	}

	return (
		<StyledMain onLoad={checkRobotsTxt()}>
			<CalculatorContext.Provider value={{ handleChange, handleCalculation, cleanCalculator }}>
				<BrowserRouter>
					<GlobalStyle />
					<TopBar calculators={calculators} />
					<Switch>
						<Route exact path='/'>
							<Navigation title={'O que deseja calcular?'} calculators={calculators} />
							<ScrollTop />
						</Route>
						{
							calculators.map((calculator, key) => {
								return (
									<Route key={key} exact path={calculator.path}>
										<Calculator key={key} calculator={calculator} calculatorId={key} />
										<CalculatorCharts calculator={calculator} />
										<SavingsInfo />
										<Navigation title={'Quer calcular algo diferente?'} calculators={calculators} />
									</Route>
								)
							})
						}

					</Switch>

					<Footer />
				</BrowserRouter>
			</CalculatorContext.Provider>
			
		</StyledMain>

	);
}

export default App;

import React from 'react';
import styled from 'styled-components';
import NavigationCard from './NavigationCard';
const Navigation = ({ title, calculators }) => {
    return (
        <StyledNavigation>
            <h3>
                {title}
            </h3>
            <div className='navigation'>
                {
                    calculators.map((calculator, index) => <div key={index} className='flex-container'><NavigationCard key={index} calculator={calculator} /></div>)
                }
            </div>
        </StyledNavigation>
    )
}

export default Navigation;

const StyledNavigation = styled.div`
    max-width: 1111px;
    margin: 0 auto;
    width: 80vw;

    .navigation{
        max-width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 16px;
        margin-top: 32px;
    }

    @media(min-width: 768px){
        .navigation{
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    @media(min-width: 1024px){
        width: 100%;
        
        .navigation{
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
`;
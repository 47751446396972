import React from 'react';
import styled from 'styled-components';

const SavingsInfo = () => {
    return (
        <CalculatorInfo>
            <h3>Sobre essa calculadora</h3>
            <p>A vida pode ser muito mais fácil quando encontramos uma ferramenta confiável para fazer contas sobre a nossa vida financeira. Foi pensando nisso que o Plusdin criou uma calculadora inteligente  para te ajudar na hora em que você mais necessita e o melhor: com simulações precisas. Agora as suas contas no final do mês vão fechar!</p>
            <p>Com ela, você vai, por exemplo, sair de férias tranquilamente. Basta inserir alguns dados simples e, em menos de 2 minutos, saber o valor real que vai receber. Diferença de salário entre contratação PJ e CLT? Também te ajudamos a saber o valor com precisão. Nossa calculadora simula tudo para você!</p>
            <p>A Conta Fácil do Plusdin é tão certeira que também é capaz de fazer contas sobre a rentabilidade do seu dinheiro. É possível saber o quanto suas economias rendem na poupança, por exemplo.</p>
            <p>Que tal testar agora mesmo? Não cobramos nada por esse serviço. Queremos apenas que você tenha as respostas para aquilo que deseja e não fique descabelado fazendo contas na ponta do lápis. Vamos lá?!</p>
        </CalculatorInfo>
    )
}
export {
    SavingsInfo,
}

const CalculatorInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 80vw;
    max-width: 1111px;
    margin: 0 auto;
    
    @media(max-width: 1024px){
        width: 100%;
        padding: 0 30px;
    }
`;